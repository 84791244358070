export class ApiError extends Error {
  /**
   * Create a new api error instance.
   *
   * @param {String} message
   * @param {Object} error
   */
  constructor(message, error = null) {
    super(message)

    this.errors = error
  }
}
