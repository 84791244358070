import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { createInertiaApp, router } from '@inertiajs/react'
import iziToast from 'izitoast'
import lightGallery from 'lightgallery'
import lgZoom from 'lightgallery/plugins/zoom'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import './bootstrap'
import Layout from './Layouts/Layout'
import { EchoContext } from '@/Context/EchoContext'
import Echo from 'laravel-echo'
import { pagesStore } from '@/Store/pages-store'
import themes from './Themes'
import { registerSW } from 'virtual:pwa-register'
import { ApiError } from '@/Errors/ApiError'

// Bugsnag options
const bugsnagOptions = {
  releaseStage: import.meta.env.MODE,
  appType: 'client',
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  onError: function (event) {
    // Try to add more information about api errors
    if (event.originalError instanceof Error && event.originalError.response) {
      const error = event.originalError

      // Extract request information
      const requestInfo = {
        method: error.config?.method,
        url: error.config?.url,
        headers: error.config?.headers,
        data: error.config?.data,
      }

      // Extract response information if available
      const responseInfo = error.response
        ? {
            status: error.response.status,
            data: error.response.data,
            headers: error.response.headers,
          }
        : null

      event.addMetadata('api', {
        status: error.response?.status,
        request: requestInfo,
        response: responseInfo,
      })
    }

    if (event.originalError instanceof ApiError) {
      event.addMetadata('apiError', {
        ...event.originalError,
      })
    }
  },
}

// Disable breadcrumbs on development
if (import.meta.env.MODE === 'development') {
  bugsnagOptions.enabledBreadcrumbTypes = []
}

Bugsnag.start(bugsnagOptions)

// Register service worker with auto update
// See: https://vite-pwa-org.netlify.app/guide/auto-update.html
registerSW({
  immediate: true,
  onRegisteredSW: (registration) => {
    console.info('Service worker registered', registration)
  },
  onRegisterError: (error) => {
    console.error(error.message)
    Bugsnag.notify(error)
  },
})

// ===== wow js
/* new WOW({
  animateClass: 'animate__animated',
}).init() */

window.iziToast = iziToast

window.LotGallery = {
  createGallery(dataSource) {
    const $dynamicGallery = document.getElementById('lot-gallery')

    const dynamicGallery = lightGallery($dynamicGallery, {
      plugins: [lgZoom],
      dynamic: true,
      dynamicEl: dataSource.map(({ src, thumb, code, name }) => {
        return {
          src,
          thumb,
          subHtml: `<h4>Lote ${code}</h4><p>${name}</p>`,
        }
      }),
    })

    return dynamicGallery
  },
}

// Error boundary

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const ErrorFallback = ({ error, clearError }) => {
  useEffect(() => {
    console.error('Error fallback component:', error)
  }, [error])

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={clearError}>Try again</button>
    </div>
  )
}

// Prevent state caching
let stale = false

window.addEventListener('popstate', () => {
  stale = true
})

router.on('navigate', (event) => {
  const page = event.detail.page
  if (stale) {
    router.get(page.url, {}, { replace: true, preserveState: false })
  }
  stale = false
})

router.on('navigate', (event) => {
  console.info(`Navigated to ${event.detail.page.url}`)

  const pageId = event.detail.page?.url

  if (pageId) {
    // Remember las two pages
    pagesStore.setState(({ latestVisits }) => {
      if (latestVisits.length >= 2) {
        latestVisits.shift()
      }

      latestVisits.push(pageId)

      return { latestVisits }
    })
  }

  document.activeElement?.blur()
})

router.on('success', (event) => {
  // Add Bugsnag user from event page detail
  const user = event.detail.page.props.auth?.user

  if (user) {
    Bugsnag.setUser(user.id, user.email, user.name)
  }
})

router.on('finish', () => {
  document.activeElement?.blur()
})

// Create unique Echo instance for app context
const echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  wsHost: window.location.hostname,
  wsPort: import.meta.env.VITE_ECHO_WS_PORT ?? 80,
  wssPort: import.meta.env.VITE_ECHO_WSS_PORT ?? 443,
  forceTLS: import.meta.env.MODE === 'production',
  enabledTransports: ['ws', 'wss'],
  disableStats: true,
})

// Theme
const theme = import.meta.env.VITE_AUCTION_THEME ?? 'default'
const themeModule = themes[theme] ?? {}

console.info('Using theme:', theme)

createInertiaApp({
  progress: {
    color: '#FFFFFF',
  },
  resolve: (name) => {
    const pages = import.meta.glob(['./Pages/**/*.jsx', './Themes/**/Pages/**/*.jsx'])
    const page = (pages[`./Themes/${theme}/Pages/${name}.jsx`] ?? pages[`./Pages/${name}.jsx`])()
    page.then((module) => {
      module.default.layout = module.default.layout || themeModule.layout || ((page) => <Layout>{page}</Layout>)
    })
    return page
  },
  setup({ el, App, props }) {
    const root = createRoot(el)

    root.render(
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EchoContext.Provider value={echo}>
          <App {...props} />
        </EchoContext.Provider>
      </ErrorBoundary>
    )
  },
}).then(() => console.info('Inertia app created'))
