import { useEffect } from 'react'
import useNotifications from '@/Hooks/useNotifications'

const FlashMessages = ({ message }) => {
  const { showAlert } = useNotifications()

  useEffect(() => {
    if (message) {
      console.debug('Status message received:', message)
      showAlert('info', message)
    }
  }, [message, showAlert])

  return null
}

export default FlashMessages
